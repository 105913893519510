/* eslint-disable default-case */
import React from "react";
import _ from "lodash";
import { Image, StyleSheet, Text, View, Dimensions } from "react-native";
import moment from "moment/moment";
import "moment-timezone";
import { browserHistory } from "react-router";
import * as KEYS from "../common/utilities/variables";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import axios from "axios";
import store from "../common/utilities/storage/store";
import { isValidPhone, validateEmail } from "../common/utilities/utils";
import { QRCode } from "react-qr-svg";
import { updateWorkflowTranslations } from "../common/utilities/i18n/utils";
import translator from "../common/utilities/i18n/";
import "../common/assets/css/invite.css";
import parse from "html-react-parser";
import { getDomainUrl } from "../veris-workflows/utils/utils";
import Calendar from "./ActivityCompleted/components/Calendar";
import Navigation from "./ActivityCompleted/components/navigation";
import "./styles.css";
import hostIcon from "../common/assets/webcheckinIcons/hostIcon.svg";
import calendarIcon from "../common/assets/webcheckinIcons/calendarIcon.svg";
import venueIcon from "../common/assets/webcheckinIcons/venueIcon.svg";
import locationIcon from "../common/assets/webcheckinIcons/locationIcon.svg";
import infoIcon from "../common/assets/webcheckinIcons/infoIcon.svg";
import verisFooterIcon from "../common/assets/webcheckinIcons/verisFooterIcon.svg";
import globeIcon from "../common/assets/webcheckinIcons/globeIcon.svg";
import dropdownArrow from "../common/assets/webcheckinIcons/dropdownArrow.svg";
import calendarBlueIcon from "../common/assets/webcheckinIcons/calendarBlueIcon.svg";
import { Button } from "antd";
import QrModal from "./ActivityCompleted/components/QrModal";
import "../index.css";

const windowWidth = Dimensions.get("window").width;

class InviteDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGDPREnabled: false,
      isGDPRModalOpen: false,
      venue_timeZone: "Asia/Kolkata",
      calendarHover: false,
      calendarModalOpen: false,
      qrModalOpen: false,
    };
    this.changeLanguage = this.changeLanguage.bind(this);
    this.config = {
      displayValue: false,
      format: "CODE39",
      width: 2,
      height: 60,
    };
  }

  componentDidMount() {
    this.fetchInviteDetails();
    let locale = localStorage.getItem("defaultLanguage") || "en";
    translator
      .changeLanguage(locale, (err, t) => {
        if (err) return console.log("something went wrong loading", err);
        t("key"); // -> same as i18next.t
        moment.locale(locale);
      })
      .then(() => {
        this.setState({});
      });
  }

  changeLanguage(e) {
    localStorage.setItem("language", e.target.value);
    translator
      .changeLanguage(e.target.value, (err, t) => {
        if (err) return console.log("something went wrong loading", err);
        t("key"); // -> same as i18next.t
        moment.locale(e.target.value);
      })
      .then(() => {
        this.setState({});
        this.setupGDPR();
      });
  }

  renderLanguageSwitcher = () => (
    <div
      style={{
        borderRadius: 3,
        position: "relative",
        display: "flex",
        alignItems: "center",
        height: windowWidth < 575 ? 26 : 40,
        // minWidth: windowWidth < 535 ? 100 : 120,
      }}
    >
      <img
        alt="globe_icon"
        src={globeIcon}
        style={
          windowWidth < 575
            ? { height: 10, width: 10, position: "absolute", left: 10 }
            : { height: 12, width: 12, position: "absolute", left: 10 }
        }
      />

      <img
        alt="arrow_icon"
        src={dropdownArrow}
        style={
          windowWidth < 575
            ? { height: 10, width: 10, position: "absolute", right: 10 }
            : { height: 12, width: 12, position: "absolute", right: 10 }
        }
      />

      <select
        style={{
          borderRadius: 5,
          height: "100%",
          // width: "100%",
          cursor: "pointer",
          padding: "0 26px",
          fontSize: windowWidth < 575 ? "10px" : "12px",
          fontFamily: "Gilroy-Medium",
          color: "#708E8C",
          border: "1px solid #708E8C",
          backgroundColor: "#FFFFFF",
          fontWeight: 700,
        }}
        // id="lang_select"
        id="language_select"
        onChange={this.changeLanguage}
      >
        <option
          value={"en"}
          selected={localStorage.getItem("language") === "en"}
        >
          English
        </option>
        <option
          value={"ja"}
          selected={localStorage.getItem("language") === "ja"}
        >
          日本語
        </option>
        <option
          value={"zh-CN"}
          selected={localStorage.getItem("language") === "zh-CN"}
        >
          中文
        </option>
        <option
          value={"es"}
          selected={localStorage.getItem("language") === "es"}
        >
          Español
        </option>
        <option
          value={"ro"}
          selected={localStorage.getItem("language") === "ro"}
        >
          Română
        </option>
        <option
          value={"hu"}
          selected={localStorage.getItem("language") === "hu"}
        >
          Magyar
        </option>
        <option
          value={"pt"}
          selected={localStorage.getItem("language") === "pt"}
        >
          Português
        </option>
      </select>
    </div>
  );

  setupGDPR = async (gdpr_consent_setting) => {
    gdpr_consent_setting =
      gdpr_consent_setting || this.props.inviteLog?.gdpr_consent_setting;
    if (gdpr_consent_setting?.enabled) {
      let language = localStorage.getItem("language") || "en";
      let config = gdpr_consent_setting?.translations?.[language];
      if (!config) {
        config = gdpr_consent_setting?.translations?.["en"];
      }
      try {
        let { data: accept_terms_html } = await axios.get(
          getDomainUrl(config?.accept_terms)
        );
        let { data: reject_terms_html } = await axios.get(
          getDomainUrl(config?.reject_terms)
        );

        this.setState({
          isGDPREnabled: true,
          // isGDPRModalOpen: true,
          accept_terms_html,
          reject_terms_html,
          accept_title: config.accept_title,
          reject_title: config.reject_title,
        });
      } catch (e) {
        console.log("e", e);
      }
    }
  };
  fetchInviteDetails = () => {
    const endpoint = "/api/v4/user/invite/workflows/";
    let queryParams = window.location.search;
    let url = endpoint + queryParams;
    let inviteLog = store.getState().inviteLog;
    if (inviteLog.inviteURL !== url) {
      store.dispatch({ type: "FLUSH_SESSION_FROM_REDUX" });
    }
    this.setState({
      isLoading: true,
    });
    axios
      .get(url)
      .then(async (response) => {
        switch (response.status) {
          case 201:
          case 200:
            localStorage.setItem(
              "language",
              response.data?.venue?.meta?.language?.default || "en"
            );
            localStorage.setItem(
              "defaultLanguage",
              response.data?.venue?.meta?.language?.default || "en"
            );
            const language =
              response.data?.venue?.meta?.language?.default || "en";
            translator.changeLanguage(language, (err, t) => {
              moment.locale(language);
            });
            let member_wallet = [];
            if (!_.isEmpty(response.data.guest_as_member)) {
              this.setState({ guestObject: response.data.guest_as_member });
            }
            if (this.state.guestObject) {
              member_wallet = this.state.guestObject?.misc_meta?.member_wallet;
            }
            let workFlows = response.data.venue_workflows.filter((workflow) => {
              return workflow.status === 1;
            });
            updateWorkflowTranslations(workFlows);
            let workflowIdFromInvite = this.decideWorkflowFromInvite(
              response.data?.workflows?.workflow_id,
              response.data.venue_workflows
            );

            if (workflowIdFromInvite) {
              workFlows = workFlows.filter(
                (v) => v.id === workflowIdFromInvite
              );
            }
            let data = {};
            data[KEYS.FORMS] = JSON.stringify(workFlows);
            data[KEYS.BASE_URL] = response.data?.base_url
              ? "https://" + response.data?.base_url
              : "https://live.veris.in";
            let guest_email = null;
            let guest_phone = null;
            let guest_contacts = response.data.guest_as_member?.contacts || [];
            guest_contacts.forEach((contact) => {
              if (isValidPhone(contact)) {
                guest_phone = contact.toString().replace("+", "");
              }
              if (validateEmail(contact)) {
                guest_email = contact;
              }
            });
            let alternateContact =
              response.data?.workflows?.workflow_data?.filter(
                (i) => i.field_code === "phone_no"
              );
            let alternateEmail =
              response.data?.workflows?.workflow_data?.filter(
                (i) => i.field_code === "email"
              );
            if (
              alternateContact?.[0] &&
              isValidPhone(alternateContact?.[0]?.value)
            ) {
              guest_phone = alternateContact[0].value
                ?.toString()
                .replace("+", "");
            }
            if (
              alternateEmail?.[0] &&
              validateEmail(alternateEmail[0]?.value)
            ) {
              guest_email = alternateEmail[0].value;
            }
            if (response.data.guest_as_member?.contacts) {
              store.dispatch({ type: "UPDATE_SESSION", data });
            }
            store.dispatch({
              type: "UPDATE_LOG",
              data: {
                visitor: {
                  first_name: response.data.guest_as_member.first_name,
                  last_name: response.data.guest_as_member.last_name,
                  mobile_number: guest_phone,
                  email: guest_email,
                  member_wallet: member_wallet,
                },
                workflow_id: workflowIdFromInvite,
                lastWorkflowData: response.data?.workflows?.workflow_data || [],
              },
            });
            if (response.data?.gdpr_consent_setting) {
              await this.setupGDPR(response.data?.gdpr_consent_setting);
            }
            store.dispatch({
              type: "UPDATE_INVITE_LOG",
              data: {
                workFlowJson: workFlows,
                inviteURL: url,
                organisation_logo: response.data.organisation_logo,
                gdpr_consent_setting: response.data.gdpr_consent_setting,
                pageNotFound: false,
                member_wallet: member_wallet,
                allWorkflows: response.data.venue_workflows,
                HostName: `${response.data.host_as_member.first_name} ${response.data.host_as_member.last_name} `,
                guest: `${response.data.guest_as_member.first_name} ${response.data.guest_as_member.last_name} `,
                Address: response.data.venue.address?.address_line_1,
                startTime: response.data?.valid_from,
                venue_timeZone:
                  response.data.venue.address?.timezone || "Asia/Kolkata",
                endTime: response.data?.valid_till,
                AdditionalInfo: response.data?.extra_instructions,
                create_invite_instructions:
                  response.data?.venue_settings?.create_invite_instructions,
                qr_code: response.data?.qr_code,
                venue: response.data?.venue.name,
                organisation: response.data?.organisation,
                guestEmail: response.data.guest_as_member.contacts[0],
                hostEmail: response.data.host_as_member.contacts[0],
                agenda: response.data?.agenda,
                position: {
                  latitude: response.data.venue.address?.latitude || "",
                  longitude: response.data.venue.address?.longitude || "",
                },
              },
            });
            return;
          case 400:
            store.dispatch({ type: "FLUSH_SESSION_FROM_REDUX" });
            this.setState({ pageNotFound: true });
        }
      })
      .catch((error) => {
        console.log("DEBUG error: ", error);
        this.setState({ pageNotFound: true });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onStartRegistration = () => {
    let workFlows = JSON.parse(this.props.session[KEYS.FORMS]);
    let screen =
      workFlows.length === 0
        ? undefined
        : workFlows.length === 1
        ? "workflow"
        : "workflowType";

    if (!screen) screen = "summary";
    if (screen === "workflow") {
      store.dispatch({
        type: "UPDATE_INVITE_LOG",
        data: {
          workflow_id: workFlows[0].id,
          workflow_name: workFlows[0]?.form_type || workFlows[0]?.title || "",
        },
      });
      screen = "workflow/page/1";
    }
    browserHistory.push({
      pathname: screen,
      state: {
        workFlows,
      },
    });
  };
  decideWorkflowFromInvite = (id, allWorkflows = []) => {
    if (!id) {
      return id;
    }
    let response;
    let workFlows = [];
    let actionToLog = "check-in";
    const filters =
      actionToLog === "check-in"
        ? { status: 1 }
        : actionToLog === "check-out"
        ? { status: 2 }
        : { status: 3 };
    workFlows = allWorkflows.filter((cond) => {
      return Object.keys(filters).every((key) => cond[key] === filters[key]);
    });
    let filterCond1 = [id];
    let workflowsFromID = _.filter(workFlows, (v) =>
      _.includes(filterCond1, v.id)
    );
    if (workflowsFromID.length > 0) {
      response = workflowsFromID[0].id;
    } else {
      let w = allWorkflows.filter((v) => v.id === id);
      let alias = (w && w[0] && w[0].meta.alias) || "";
      workFlows.forEach((w) => {
        if (alias && w.meta?.alias && alias === w.meta?.alias) {
          response = w.id;
        }
      });
    }
    return response;
  };

  renderTimeRange = () => {
    const startDate = moment(this.state.startTime)
      .tz(this.state.venue_timeZone)
      .format("DD MMM YYYY");
    const startTime = moment(this.state.startTime)
      .tz(this.state.venue_timeZone)
      .format("hh:mm A");

    const endDate = moment(this.state.endTime)
      .tz(this.state.venue_timeZone)
      .format("DD MMM YYYY");
    const endTime = moment(this.state.endTime)
      .tz(this.state.venue_timeZone)
      .format("hh:mm A");

    if (startDate === endDate) {
      return (
        <div>
          {startDate}
          {", "}
          <span
            style={{
              fontWeight: "700",
              fontFamily: "Gilroy-Medium",
              color: "#2F4644",
            }}
          >{`${startTime} - ${endTime}`}</span>{" "}
        </div>
      );
    } else {
      return (
        <div>
          {startDate}
          {", "}
          <span
            style={{
              fontWeight: "700",
              fontFamily: "Gilroy-Medium",
              color: "#2F4644",
            }}
          >{`${startTime} -`}</span>{" "}
          {endDate}
          {", "}
          <span
            style={{
              fontWeight: "700",
              fontFamily: "Gilroy-Medium",
              color: "#2F4644",
            }}
          >
            {endTime}
          </span>{" "}
        </div>
      );
    }
  };

  render() {
    this.state = { ...this.state, ...this.props.inviteLog };

    // [Loader]
    if (this.state.isLoading) {
      return (
        <View
          style={{
            flex: 1,
            padding: 10,
            backgroundColor: "#fefefe",
            height: "100vh",
          }}
        >
          <Loader loadingMessage={"Fetching your invite details..."} />
        </View>
      );
    }
    // [Invalid Link]
    else if (this.state.pageNotFound) {
      return (
        <View
          style={{
            flex: 1,
            padding: 10,
            backgroundColor: "#fefefe",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Image
            resizeMode={"contain"}
            style={{
              height: 100,
            }}
            source={require("../common/assets/images/exclamation.png")}
          />
          <br />
          <Text
            numberOfLines={2}
            style={[styles.keyText, { textAlign: "center", color: "orange" }]}
          >
            This link is not available or has expired.
          </Text>
          <hr />
        </View>
      );
    }

    // [GDPR modal]
    if (this.state.isGDPRModalOpen) {
      let title = this.state.accept_title;
      let htmlToRender = this.state.accept_terms_html;
      if (this.state.isGDPRRejected) {
        title = this.state.reject_title;
        htmlToRender = this.state.reject_terms_html;
      }
      return (
        <div
          style={{
            height: "100vh",
          }}
        >
          <div
            className="organisation_logo_holder"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: "90px",
              padding: "4px 14px",
            }}
          >
            <img
              alt=""
              src={this.state.organisation_logo}
              style={{ width: "49%", maxWidth: "140px", objectFit: "contain" }}
            />
            <View
              style={{
                width: "50%",
                paddingBottom: 10,
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            />
            {/*{this.renderLanguageSwitcher()}*/}
          </div>
          <div id="gdpr-popup-container">
            <div className="gdpr__text">
              <h4
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                {title}
              </h4>
              <div
                id="htmlPDF"
                style={{
                  flex: 1,
                  textAlign: "left",
                  marginTop: "2em",
                  maxHeight: "450px",
                  overflow: "scroll",
                  padding: "0 15px 0 15px",
                  marginVertical: "2em",
                  alignSelf: "center",
                }}
              >
                {htmlToRender ? (
                  parse(htmlToRender)
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignSelf: "center",
                      margin: "auto",
                      padding: "20%",
                    }}
                  >
                    {" "}
                    Loading please wait...
                  </div>
                )}
              </div>
            </div>
            <div
              className="gdpr__choices"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginBottom: "10px",
                paddingTop: "10px",
              }}
            >
              {this.state.isGDPRRejected ? (
                <button
                  type="button"
                  id="gdpr-cancel"
                  className="btn phn_email_input primary-button btn-lg"
                  onClick={() => {
                    store.dispatch({ type: "FLUSH_SESSION_FROM_REDUX" });
                    this.setState({
                      isGDPRModalOpen: false,
                      pageNotFound: true,
                    });
                  }}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    id="gdpr-cancel"
                    className="btn primary-border-button btn-lg"
                    onClick={() => {
                      this.setState({
                        isGDPRRejected: true,
                      });
                    }}
                  >
                    I Reject
                  </button>

                  <button
                    id="gdpr-ok"
                    className="btn  primary-button btn-lg"
                    onClick={() => {
                      this.onStartRegistration();
                      this.setState({
                        isGDPRModalOpen: false,
                      });
                    }}
                  >
                    I Accept
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }

    // [Details Component]
    return (
      <View
        style={
          windowWidth < 575
            ? styles.detailsContainerMobile
            : styles.detailsContainer
        }
      >
        {/* [calendarModalOpen] */}
        {this.state.calendarModalOpen && (
          <Calendar
            {...this.state}
            setCalendarModalOpen={(state) =>
              this.setState({ calendarModalOpen: state })
            }
          />
        )}

        {/* [qrModalOpen] */}
        {this.state.qrModalOpen && (
          <QrModal
            setQrModalOpen={(state) => this.setState({ qrModalOpen: state })}
            qr_code={this.state.qr_code}
          />
        )}

        <View style={windowWidth < 575 ? { padding: 16 } : { padding: 20 }}>
          {/* [Header] */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              paddingBottom: 16,
              // alignItems: "center",
              borderBottom: "1px solid #D3D3D3",
            }}
          >
            <img
              alt="org_logo"
              src={this.state.organisation_logo}
              style={
                windowWidth < 575
                  ? { maxWidth: "120px", height: "30px", objectFit: "contain" }
                  : { maxWidth: "170px", height: "50px", objectFit: "contain" }
              }
            />
            {this.renderLanguageSwitcher()}
          </View>

          {/* [Details] */}
          <View
            style={{
              marginTop: 20,
            }}
          >
            {/* [Name, Venue] */}
            <Text
              style={
                windowWidth < 575 ? styles.nameTextMobile : styles.nameText
              }
            >
              {translator.t("Hi") + " " + this.state.guest?.trim()},
            </Text>

            <Text
              style={
                windowWidth < 575 ? styles.venueTextMobile : styles.venueText
              }
            >
              {translator.t("You've been invited to") + " " + this.state.venue}
            </Text>

            {/* QR and Info */}
            <View style={styles.infoContainer}>
              {/* [Info] */}
              <View style={{ display: "flex", flexDirection: "row" }}>
                <img
                  alt="host_icon"
                  src={hostIcon}
                  style={
                    windowWidth < 575
                      ? { height: 12, width: 12, marginRight: 10, marginTop: 2 }
                      : { height: 18, width: 18, marginRight: 12, marginTop: 4 }
                  }
                />
                <Text
                  style={
                    windowWidth < 575
                      ? styles.infoTextBoldMobile
                      : styles.infoTextBold
                  }
                >
                  {this.state.HostName}
                  <Text
                    style={
                      windowWidth < 575
                        ? styles.infoTextLightMobile
                        : styles.infoTextLight
                    }
                  >{`(${translator.t("Host")})`}</Text>{" "}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: windowWidth < 575 ? 10 : 8,
                }}
              >
                <img
                  alt="calendar_icon"
                  src={calendarIcon}
                  style={
                    windowWidth < 575
                      ? { height: 12, width: 12, marginRight: 10, marginTop: 2 }
                      : { height: 18, width: 18, marginRight: 12, marginTop: 4 }
                  }
                />
                <Text
                  style={
                    windowWidth < 575
                      ? styles.infoTextLightMobile
                      : styles.infoTextLight
                  }
                >
                  {this.renderTimeRange()}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: windowWidth < 575 ? 10 : 8,
                }}
              >
                <img
                  alt="venue_icon"
                  src={venueIcon}
                  style={
                    windowWidth < 575
                      ? { height: 12, width: 12, marginRight: 10, marginTop: 2 }
                      : { height: 18, width: 18, marginRight: 12, marginTop: 4 }
                  }
                />
                <Text
                  style={
                    windowWidth < 575
                      ? styles.infoTextBoldMobile
                      : styles.infoTextBold
                  }
                >
                  {this.state.organisation}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: windowWidth < 575 ? 10 : 8,
                }}
              >
                <img
                  alt="location_icon"
                  src={locationIcon}
                  style={
                    windowWidth < 575
                      ? {
                          height: 12,
                          minWidth: 12,
                          maxWidth: 12,
                          marginRight: 10,
                          marginTop: 2,
                        }
                      : {
                          height: 18,
                          minWidth: 18,
                          maxWidth: 18,
                          marginRight: 12,
                          marginTop: 6,
                        }
                  }
                />
                <Text
                  style={
                    windowWidth < 575
                      ? styles.infoTextLightMobile
                      : styles.infoTextLight
                  }
                >
                  {this.state.venue}, {this.state.Address}
                </Text>
              </View>

              {/* [QR] */}
              <View style={styles.qrContainer}>
                <QRCode
                  value={this.state.qr_code}
                  style={{
                    width: windowWidth < 575 ? "35%" : "40%",
                    padding: 8,
                    borderRadius: 3,
                    backgroundColor: "#FFFFFF",
                    marginRight: 15,
                    cursor: "pointer",
                  }}
                  onClick={() => this.setState({ qrModalOpen: true })}
                />

                <View style={{ width: windowWidth < 575 ? "65%" : "60%" }}>
                  <Navigation
                    position={this.state.position}
                    org_name={this.state.organisation}
                  />

                  <Button
                    style={
                      windowWidth < 575
                        ? {
                            display: "flex",
                            // justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#FFFFFF",
                            color: "#4F7471",
                            fontSize: "12px",
                            fontWeight: 700,
                            whiteSpace: "normal",
                            height: 38,
                            borderColor: "#B2C3C1",
                            borderWidth: 1,
                            borderRadius: 5,
                            width: "100%",
                            marginTop: 12,
                            padding: "0 12px",
                            fontFamily: "Gilroy-Medium",
                          }
                        : {
                            display: "flex",
                            // justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#FFFFFF",
                            color: "#4F7471",
                            fontSize: "20px",
                            fontWeight: 700,
                            whiteSpace: "normal",
                            height: 52,
                            borderColor: "#B2C3C1",
                            borderWidth: 1,
                            borderRadius: 5,
                            width: "100%",
                            marginTop: 40,
                            padding: "0 12px",
                            fontFamily: "Gilroy-Medium",
                          }
                    }
                    icon={
                      <img
                        alt="calendar_icon"
                        src={calendarBlueIcon}
                        style={
                          windowWidth < 575
                            ? { height: 14, width: 14, marginRight: 10 }
                            : { height: 18, width: 18, marginRight: 12 }
                        }
                      />
                    }
                    onClick={() => {
                      this.setState({ calendarModalOpen: true });
                    }}
                  >
                    {translator.t("Add to calendar")}
                  </Button>
                </View>
              </View>

              {/* [Additional Info] */}

              {!_.isEmpty(this.state.create_invite_instructions) && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <img
                    alt="info_icon"
                    src={infoIcon}
                    style={
                      windowWidth < 575
                        ? { height: 12, width: 12, marginRight: 6 }
                        : { height: 18, width: 18, marginRight: 6 }
                    }
                  />

                  <Text
                    style={
                      windowWidth < 575
                        ? styles.additionalInfoTextMobile
                        : styles.additionalInfoText
                    }
                  >
                    {this.state.create_invite_instructions}
                  </Text>
                </View>
              )}

              {!_.isEmpty(this.state.AdditionalInfo) && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="info_icon"
                    src={infoIcon}
                    style={
                      windowWidth < 575
                        ? { height: 12, width: 12, marginRight: 6 }
                        : { height: 18, width: 18, marginRight: 6 }
                    }
                  />

                  <Text
                    style={
                      windowWidth < 575
                        ? styles.additionalInfoTextMobile
                        : styles.additionalInfoText
                    }
                  >
                    {this.state.AdditionalInfo}
                  </Text>
                </View>
              )}

              {/* <div
                style={{
                  height: 1,
                  width: "100%",
                  backgroundColor: "#D3DDDC",
                  marginTop: 12,
                }}
              /> */}

              {/* [Web checkin Button] */}
              <button
                disabled={this.state.qrModalOpen}
                style={{
                  marginTop: 20,
                  backgroundColor: "#34C759",
                  border: "none",
                  color: "#FFFFFF",
                  border: "none",
                  borderRadius: 5,
                  height: windowWidth < 575 ? 44 : 56,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: windowWidth < 575 ? "14px" : "20px",
                  fontFamily: "Gilroy-Medium",
                  fontWeight: 700,
                }}
                type="button"
                onClick={() => {
                  if (this.state.isGDPREnabled) {
                    this.setState({ isGDPRModalOpen: true });
                  } else {
                    this.onStartRegistration();
                  }
                }}
              >
                {translator.t("Begin WebCheckin")}
              </button>
            </View>

            {/* [Footer Text] */}
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <img
                alt="info_icon"
                src={infoBulbIcon}
                style={{ height: 16, width: 10, marginRight: 8 }}
              />

              <Text
                style={
                  windowWidth < 575
                    ? styles.footerTextMobile
                    : styles.footerText
                }
              >
                {translator.t("Experience a super fast check in by completing the Web Check in process.")}
              </Text>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  style={
                    windowWidth < 575
                      ? styles.footerTextMobile
                      : styles.footerText
                  }
                >
                  {translator.t("info-checkIn-super-fast-venue")}
                </Text>
                <Text
                  style={
                    windowWidth < 575
                      ? styles.footerTextMobile
                      : styles.footerText
                  }
                >
                  {translator.t("dont-fill-form-again")}
                </Text>
              </div>
            </div> */}
          </View>
        </View>

        {/* [Footer logo] */}
        <div
          // style={
          //   windowWidth < 575
          //     ? {
          //         display: "flex",
          //         justifyContent: "center",
          //         alignItems: "center",
          //         position: "absolute",
          //         bottom: 40,
          //         width: "calc(100% - 32px)",
          //       }
          //     : {
          //         display: "flex",
          //         justifyContent: "center",
          //         width: "100%",
          //       }
          // }
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <a
            href="https://www.getveris.com/"
            style={{ cursor: "pointer" }}
            target="_blank"
            title="Powered By Veris"
          >
            <img
              alt="Powered By Veris"
              src={verisFooterIcon}
              style={{ objectFit: "contain", height: 35, width: 140 }}
            />
          </a>
        </div>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  detailsContainer: {
    flex: 1,
    minHeight: "100vh",
    backgroundColor: "#FFFFFF",
    position: "relative",
  },

  nameText: {
    fontSize: "32px",
    fontFamily: "Gilroy-Medium",
    lineHeight: "36px",
    fontWeight: "700",
    color: "#002438",
    textAlign: "center",
  },

  venueText: {
    fontSize: "20px",
    fontFamily: "Gilroy",
    lineHeight: "28px",
    fontWeight: "400",
    color: "#2F4644",
    textAlign: "center",
    marginTop: 2,
  },

  infoContainer: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#D3DDDC",
    borderRadius: 15,
    marginTop: 10,
    paddingTop: 14,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    backgroundColor: "#F6F6F6",
  },

  infoTextBold: {
    fontSize: "18px",
    fontFamily: "Gilroy-Medium",
    lineHeight: "28px",
    fontWeight: "700",
    color: "#2F4644",
  },

  infoTextLight: {
    fontSize: "18px",
    fontFamily: "Gilroy",
    lineHeight: "28px",
    fontWeight: "400",
    color: "#2F4644",
  },

  qrContainer: {
    width: "100%",
    marginTop: 14,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  additionalInfoText: {
    color: "#2F4644",
    fontSize: "14px",
    fontFamily: "Gilroy-Medium",
    fontWeight: "700",
  },

  footerText: {
    color: "#4F7471",
    fontSize: "14px",
    fontFamily: "Gilroy-Medium",
    fontWeight: "700",
    lineHeight: "20px",
  },

  keyText: {
    fontSize: "16px",
    minWidth: 135,
    color: "#2F4644",
    fontWeight: "400",
  },

  // [Mobile css]
  detailsContainerMobile: {
    flex: 1,
    height: "100vh",
    backgroundColor: "#FFFFFF",
    position: "relative",
  },

  nameTextMobile: {
    fontSize: "24px",
    fontFamily: "Gilroy-Medium",
    lineHeight: "36px",
    fontWeight: "700",
    color: "#002438",
    textAlign: "center",
  },

  venueTextMobile: {
    fontSize: "14px",
    fontFamily: "Gilroy",
    lineHeight: "14px",
    fontWeight: "400",
    color: "#2F4644",
    textAlign: "center",
    marginTop: 2,
  },

  infoTextBoldMobile: {
    fontSize: "12px",
    fontFamily: "Gilroy-Medium",
    lineHeight: "14px",
    fontWeight: "700",
    color: "#2F4644",
  },

  infoTextLightMobile: {
    fontSize: "12px",
    fontFamily: "Gilroy",
    lineHeight: "14px",
    fontWeight: "400",
    color: "#2F4644",
  },

  additionalInfoTextMobile: {
    color: "#2F4644",
    fontSize: "10px",
    fontFamily: "Gilroy-Medium",
    fontWeight: "700",
  },

  footerTextMobile: {
    color: "#4F7471",
    fontSize: "10px",
    fontFamily: "Gilroy-Medium",
    fontWeight: "700",
    lineHeight: "14px",
  },
});

function mapStateToProps(state) {
  return {
    session: state.session,
    style: state.style,
    activityLog: state.activityLog,
    inviteLog: state.inviteLog,
  };
}

export default connect(mapStateToProps)(InviteDetails);
